import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { active: Boolean }

  unlockContent(event) {
    // Check if the paywall is active before redirecting
    if (this.activeValue) {
      event.preventDefault();
      window.location.href = "/lender-hub/plans";
    }
  }
}
