import { Controller } from "@hotwired/stimulus";
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';

export default class extends Controller {
  static values = { tableType: Number }
  static targets = ["loadingIndicator", "table"]

  connect() {
    this.fetchDataAndInitTabulator();
  }

  fetchDataAndInitTabulator() {
    this.loadingIndicatorTarget.classList.remove('hidden');
    const endpoint = this.getTableEndpoint(this.tableTypeValue);
    fetch(endpoint)
      .then(async response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return await response.json();
      })
      .then(data => {
        const filteredData = data.filter(item => item.loan_amount && item.loan_amount !== 0);
        // Parse and store date for sorting
        filteredData.forEach(item => {
          if (item.last_engagement?.created_at) {
            item.last_engagement_date = new Date(item.last_engagement.created_at).toISOString(); // Storing ISO string
          } else {
            item.last_engagement_date = null;
          }
        });
        this.initTabulator(filteredData, this.tableTypeValue);
        console.log("Filtered data: ", filteredData);
        this.loadingIndicatorTarget.classList.add('hidden');
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
        this.loadingIndicatorTarget.classList.add('hidden');
      });
  }

  getTableEndpoint(tableType) {
    switch (tableType) {
      case 1:
        return '/lender-hub/lender_clients';
      case 2:
        return '/lender-hub/lender_clients';
      default:
        return '/default-endpoint';
    }
  }

  getTableSettings(tableType, data) {
    let settings = {};
    switch (tableType) {
      case 1:
        settings = {
          height: 205,
          layout: "fitColumns",
          columns: [
            {title: "Name", field: "borrower_first_name" },
            {title: "Surname", field: "borrower_last_name"},
            {title: "Loan Amount", width: 100, field: "loan_amount", hozAlign: "left", formatter: "money", formatterParams: {symbol: "$", precision: false}},
            {title: "Quoted Rate", width: 100, field: "quote_rate", hozAlign: "left"},
            {title: "Loan Program", field: "loan_program"},
            {title: "Closed Date", field: "actual_closed_date", sorter: "date", hozAlign: "center"},
            {title: "Street Address", field: "street_address_1", hozAlign: "center"},
            {title: "City", field: "city", hozAlign: "center"},
            {title: "State", width: 100, field: "state", hozAlign: "center"},
            {title: "Zip", width: 100, field: "zip_code", hozAlign: "center"},
            {title: "Email", width: 100, field: "borrower_email", hozAlign: "center"},
            {title: "Last Monthly Report Sent", field: "monthly_sent_email", sorter: "date", hozAlign: "center"},
            {title: "Retained Status", field: "lender_client_stat.retained_status", hozAlign: "center"},
            {title: "Email Interactions", field: "email_interactions_count", hozAlign: "center", headerSort: false}
          ],
          data,
        };
        break;
      case 2:
        settings = {
          height: 700,
          layout: "fitColumns",
          columns: [
            {
              title: "Name",
              field: "borrower_first_name",
              width: 150,
              hozAlign: "center",
              formatter: function(cell, formatterParams, onRendered){
                const firstName = cell.getRow().getData().borrower_first_name;
                const lastName = cell.getRow().getData().borrower_last_name;
                return firstName + ' ' + lastName;
              }
            },
            {title: "Status", width: 100, field: "lender_client_stat.lead_status", hozAlign: "center"},
            {
              title: "Contact",
              field: "borrower_home_phone",
              width: 175,
              hozAlign: "center",
              formatter: function(cell, formatterParams, onRendered) {
                const phone = cell.getRow().getData().borrower_home_phone;
                const email = cell.getRow().getData().borrower_email;
              
                const emailLink = `<a href="mailto:${email}" title="${email}" style="display: inline-block; vertical-align: middle; width: auto; margin-right: 5px;"><svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail" style="background-color: #fefbea; padding: 2px; border-radius: 3px;"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></a>`;
              
                return `<span style="white-space: nowrap; display: inline-block; width: 100%;">${phone ? phone + '  ' : ''}${emailLink}</span>`;
              }
              
            },
            {title: "Loan Amount", width: 150, headerTooltip: true, field: "loan_amount", hozAlign: "center", formatter: "money", formatterParams: {symbol: "$", precision: false}},
            {title: "Rate", width: 100, field: "quote_rate", hozAlign: "center"},
            {title: "Loan Officer", width: 150, field: "loan_officer.name", hozAlign: "center"},
            {title: "Prev Agent", width: 150, field: "most_recent_agent", hozAlign: "center", headerSort: false},
            {
              title: "Last Engagement",
              width: 325,
              field: "last_engagement_date",  // Use the raw date field for sorting
              hozAlign: "center",
              headerSort: true,  // Enable sorting
              sorter: function(a, b, aRow, bRow, column, dir, sorterParams) {
                // Convert both values to Date objects for comparison
                const dateA = new Date(a).getTime();
                const dateB = new Date(b).getTime();
            
                // Handle null or invalid dates
                if (!dateA) return -1;
                if (!dateB) return 1;
            
                return dateA - dateB; // Compare dates for sorting
              },
              formatter: function(cell, formatterParams, onRendered){
                const data = cell.getRow().getData();
                const lastEngagement = data.last_engagement;
            
                if (lastEngagement?.action && lastEngagement.created_at) {
                  const dateFormatted = new Date(lastEngagement.created_at).toLocaleDateString("en-US", {
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit'
                  });
            
                  return `${lastEngagement.action}: ${dateFormatted}`;
                } else {
                  return "";
                }
              }
            },
            {title: "Email Interactions",field: "email_interactions_count", width: 100, hozAlign: "center", headerTooltip: true, headerSort: true},
            {
              title: "Opportunity Type",
              field: "lender_client_stat.opportunity_type",
              tooltip: true,
              hozAlign: "center",
              formatter: function(cell, formatterParams, onRendered){
                const opportunities = cell.getValue() || [];
                const chips = opportunities.map(opportunity => 
                  `<span class="opportunity-chip">${opportunity}</span>`
                ).join(' ');
                return chips;
              },
              cellStyled:function(cell){
                // I'll leave this in for now as we may want to customize chip color depending on opportunity type
              }
            },
            {
              title: "Value Report",
              field: "id",
              width: 150,
              hozAlign: "center",
              formatter: function(cell, formatterParams, onRendered){
                const lenderClientId = cell.getValue();
                const url = `/value_report?lender_client_id=${lenderClientId}`;
                return `<a href="${url}" target="_blank" style="color: #007bff; text-decoration: underline; cursor: pointer;">View Report</a>`;
              },
            }
          ],
          data,
        };
        break;
        default:
          settings = {
            height: 205,
            layout: "fitColumns",
            columns: [],
            data, 
          };
    }
    return settings;
  }

  initTabulator(data, tableType) {
    const settings = this.getTableSettings(tableType, data);
    new Tabulator(this.element, settings);
  }
}
