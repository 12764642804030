import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["source", "destination"];

  connect() {
    this.initSortable();
    this.checkRequiredMappings();
  }

  initSortable() {
    Sortable.create(this.sourceTarget, {
      group: 'shared',
      animation: 150,
    });

    this.destinationTargets.forEach(destination => {
      Sortable.create(destination, {
        group: 'shared',
        animation: 150,
        sort: false,
        onAdd: (event) => {
          this.handleAddToDestination(event);
        },
        onRemove: (event) => {
            this.handleRemoveFromDestination(event);
        },
      });
    });
  }

  handleAddToDestination(event) {
    // limit destination slots to one item
    if (event.to.children.length > 1) {
      const newItem = event.item;
      const currentItem = event.to.children[0] === newItem ? event.to.children[1] : event.to.children[0];
  
      this.sourceTarget.appendChild(currentItem);
    }

    this.updateMapping(event.to.dataset.id, event.item.dataset.header);
  }

  handleRemoveFromDestination(event) {
    this.updateMapping(event.from.dataset.id, '');
  }

  updateMapping(columnId, headerValue) {
    const input = document.getElementById(`${columnId}-input`);
    if (input) {
      input.value = headerValue;
    }
    this.checkRequiredMappings();
  }

  checkRequiredMappings() {
    let allRequiredFilled = true;
    for (let i = 1; i <= 9; i++) { // Check first nine columns
      const input = document.getElementById(`default-column-${i}-input`);
      if (!input || input.value === '') {
        allRequiredFilled = false;
        break;
      }
    }
    
    const submitButton = document.querySelector("#mapping-form input[type='submit']");
    if (submitButton) {
      submitButton.disabled = !allRequiredFilled;
      if (allRequiredFilled) {
        // Remove disabled styles
        submitButton.classList.remove('opacity-50', 'cursor-not-allowed');
        submitButton.classList.add('hover:bg-blue-600', 'cursor-pointer');
      } else {
        // Add disabled styles
        submitButton.classList.add('opacity-50', 'cursor-not-allowed');
        submitButton.classList.remove('hover:bg-blue-600', 'cursor-pointer');
      }
    }
  }  
}
