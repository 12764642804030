import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static values = { data: Object }

  connect() {
    this.createChart(this.dataValue);
  }

  createChart(chartData) {
    const ctx = this.element.getContext('2d');
    new Chart(ctx, {
      type: chartData.type,
      data: {
        labels: chartData.labels,
        datasets: chartData.datasets
      },
      options: chartData.options || {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}
