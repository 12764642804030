(function () {
  if (document.URL.match(/\/home_valuation\/lookup_step2/)) {
    const mortgage = document.getElementById("mortgage");
    const USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    mortgage.addEventListener("keyup", calculate_savings);

    function calculate_savings() {
      const mortgage_value = mortgage.value.replace(/[^0-9.-]+/g, "");

      mortgage.value = USDollar.format(mortgage_value);
    }
  }

  if (document.URL.match(/\/home_valuation\/lookup_step3/)) {
    const btn_realtor = document.getElementById("sell_with_realtor");
    const btn_list_free = document.getElementById("list_for_free");
    const prop_calcs = document.getElementById("prop_calcs");
    const sign_up_message = document.getElementById("sign_up_message");

    if (sign_up_message) {
      console.log(true);
      btn_realtor.addEventListener("click", handleClick);
      btn_list_free.addEventListener("click", handleClick);
    }

    function handleClick(event) {
      const new_sign_up_message =
        '<div class="red-block rounded-md mb-4" style="background-color: #F04040;">\n' +
        '<p class="text-f14 font-normal">Please login or sign up to continue.</p>\n' +
        "</div>";
      event.preventDefault();
      prop_calcs.classList.remove("mt-12");
      prop_calcs.classList.add("mt-20");
      sign_up_message.innerHTML = new_sign_up_message;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
})();
