import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["success", "customerEmail"]

  connect() {
    this.initialize();
  }

  async initialize() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const response = await fetch(`/checkout/session-status?session_id=${sessionId}`);
    const session = await response.json();

    if (session.status === 'open') {
      window.location.replace("/lender-hub/checkout");
    } else if (session.status === 'complete') {
      this.successTarget.classList.remove('hidden');
      this.customerEmailTarget.textContent = session.customer_email;
    }
  }
}
