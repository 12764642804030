import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { comment } from "postcss";

export default class extends Controller {
  static targets = ["menu"];

  currentFocusedElement = null;

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.handleClickOutside);
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.currentFocusedElement) {
      this.currentFocusedElement.focus();
      this.currentFocusedElement = null;
    }
    if (!event.target.closest('[data-floating-options-target="menu"]')) {
      this.closeAllMenus();
    }
  }

  closeAllMenus() {
    const allMenus = document.querySelectorAll(
      '[data-floating-options-target="menu"]',
    );
    allMenus.forEach((menu) => { menu.classList.add("hidden"); });
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();
    const menu = this.menuTarget;
    console.log("menu", menu)
    const wasHidden = menu.classList.contains("hidden");
    const button = event.currentTarget;

    this.currentFocusedElement = button;
    this.closeAllMenus();

    if (wasHidden) {
      this.showMenu(event);
    }
  }

  showMenu(event) {
    if (this.currentFocusedElement) {
      this.currentFocusedElement.focus();
      this.currentFocusedElement = null;
    }
    const button = event.currentTarget;
    const identifier = button.dataset.identifier;
    const context = button.dataset.context;
    const container = button.closest("[data-floating-options-container]");
    console.log("container", container)
    console.log("button", button)
    console.log("identifier", identifier)
    console.log("context", context)
    
    const menu = document.getElementById(
      `options-menu-${context}-${identifier}`,
    );
    const commentScrollContainer = document.getElementById(
      `comment-scroll-container`,
    );
    // ^ I recently added this commentScrollContainer logic to work for mobile. If you're seeing strange behaviors for floating options menu elsewhere, this might be the culprit

    if (!menu || !container) {
      console.error(`identifier: ${identifier}`);
      return;
    }

    // Temporarily make the menu visible for measurement
    menu.style.visibility = "hidden";
    menu.classList.remove("hidden");

    const menuWidth = menu.offsetWidth;
    const menuHeight = menu.offsetHeight;

    menu.style.visibility = "";
    menu.classList.add("hidden");

    // Get the offset position of the button
    let top = button.offsetTop;
    let left = button.offsetLeft;

    top += button.offsetHeight;
    console.log(
      "comment scroll container scrollTop",
      commentScrollContainer.scrollTop,
    );

    top += container.scrollTop - commentScrollContainer.scrollTop;
    left += container.scrollLeft;

    // Check if the menu goes off the right edge of the viewport and then set its final position
    if (left + menuWidth > window.innerWidth) {
      left -= left + menuWidth - window.innerWidth;
    }

    menu.style.position = "absolute";
    menu.style.top = `${top}px`;
    menu.style.left = `${left}px`;

    menu.classList.remove("hidden");
    menu.style.visibility = "visible";

    setTimeout(() => {
      document.addEventListener("click", this.handleClickOutside);
    }, 0);
  }

  hideMenu() {
    this.menuTarget.classList.add("hidden");
  }

  menuClicked(event) {
    event.stopPropagation();
  }

  triggerTurbo(event) {
    event.preventDefault();
    const url = event.currentTarget.getAttribute("href");
    console.log("url", url);
    const frame = event.currentTarget.dataset.turboFrame;
    console.log("frame", frame);

    this.closeAllMenus();
    if (url && frame) {
      console.log("do the Turbo Visit");
      Turbo.visit(url, { frame });
    } else {
      console.error("Error triggering Turbo: URL or frame is null", {
        url,
        frame,
      });
    }
  }

  handleDelete(event) {
    event.preventDefault();
    const url = event.currentTarget.getAttribute("href");
    console.log("url", url);
    const confirmMessage = event.currentTarget.dataset.turboConfirm;

    if (!confirmMessage || window.confirm(confirmMessage)) {
      const token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");
      const requestHeaders = new Headers();
      requestHeaders.append("X-CSRF-Token", token);
      requestHeaders.append("Accept", "text/vnd.turbo-stream.html");

      fetch(url, {
        method: "DELETE",
        headers: requestHeaders,
        credentials: "same-origin",
      })
        .then(async (response) => {
          if (response.ok) {
            return await response.text();
          } else {
            throw new Error("Network response was not ok.");
          }
        })
        .then((html) => {
          Turbo.renderStreamMessage(html);
        })
        .catch((error) => {
          console.error("Failed to delete:", error);
        });
    }
  }
}
