window.shareContent = function () {
  const propertyTitleElement = document.querySelector(".heading-custom-h4");
  const propertyTitle = propertyTitleElement
    ? propertyTitleElement.innerText
    : "Property on OTTO";

  const propertyDescriptionElement = document.querySelector(
    ".para-graytext-sans.summary"
  );
  const propertyDescription = propertyDescriptionElement
    ? propertyDescriptionElement.innerText
    : "";
  const truncatedDescription =
    propertyDescription.length > 150
      ? propertyDescription.slice(0, 150) + "..."
      : propertyDescription;

  const propertyHighlightsElements = document.querySelectorAll(".highlight-item");
  let highlightsText = "";

  propertyHighlightsElements.forEach((highlight) => {
    const highlightText = highlight.innerText.toLowerCase();
    if (
      highlightText.includes("pool") ||
      highlightText.includes("ac") ||
      highlightText.includes("fireplace") ||
      highlightText.includes("garage")
    ) {
      highlightsText += `- ${highlight.innerText}\n`;
    }
  });

  const propertyPriceElement = document.querySelector(
    ".heading-custom-h4.text-letterBlack"
  );
  const propertyPrice = propertyPriceElement
    ? propertyPriceElement.innerText
    : "Price not available";

  const propertyUrl = window.location.href;

  const shareTitle = "Check out this property!";
  let shareText = `Price: ${propertyPrice}\n\nDescription:\n${truncatedDescription}`;


  if (highlightsText) {
    shareText += `\nHighlights:\n${highlightsText}`;
  }

  shareText += `\nView property here: ${propertyUrl}`;

  if (navigator.share) {
    navigator
      .share({
        title: shareTitle,
        text: shareText,
      })
      .catch(() => {
        showNotification("Error occurred while sharing content.");
      });
  } else {
    showNotification("Sharing is not available on this browser.");
  }
};

function showNotification(message) {
  const notification = document.createElement("div");
  notification.className =
    "fixed bottom-4 right-4 bg-red-500 text-white py-2 px-4 rounded-lg shadow-lg z-50";
  notification.innerText = message;

  document.body.appendChild(notification);

  setTimeout(() => {
    notification.remove();
  }, 3000);
}
