(function () {
  if (document.URL.match(/\/properties\/[0-9]*\/edit/)) {
    const selling_price = document.getElementById("selling_price");
    const min_price = document.getElementById("min_price");
    const mortgage = document.getElementById("mortgage");
    const zestimateVal = document.getElementById("zestimateVal");
    const realtyMoleVal = document.getElementById("realtyMoleVal");
    const USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    selling_price.addEventListener("keyup", calculate_savings);
    min_price.addEventListener("keyup", calculate_savings);
    mortgage.addEventListener("keyup", calculate_savings);

    function calculate_savings() {
      const selling_price_value = selling_price.value.replace(/[^0-9.-]+/g, "");
      const min_price_value = min_price.value.replace(/[^0-9.-]+/g, "");
      const mortgage_value = mortgage.value.replace(/[^0-9.-]+/g, "");
      if (zestimateVal) {
        const zestimateValue = zestimateVal.innerhtml.replace(/[^0-9.-]+/g, "");
      }
      if (realtyMoleVal) {
        const realtyMoleValue = realtyMoleVal.innerhtml.replace(/[^0-9.-]+/g, "");
      }

      selling_price.value = USDollar.format(selling_price_value);
      min_price.value = USDollar.format(min_price_value);
      mortgage.value = USDollar.format(mortgage_value);

      if (otto_valuation) {
        const highest_valuation = calculate_highest_valuation(
          otto_valuation,
          zestimateValue,
          realtyMoleValue,
        );
        if (min_price_value > (otto_valuation / 0.85) * 0.95) {
          document.getElementById("min_offer_alert").style.display = "block";
          min_price.value = USDollar.format(
            Math.round((otto_valuation / 0.85) * 0.95),
          );
        } else {
          document.getElementById("min_offer_alert").style.display = "none";
        }
        if (selling_price_value > (otto_valuation / 0.85) * 1.2) {
          document.getElementById("selling_price_alert").style.display =
            "block";
        } else {
          document.getElementById("selling_price_alert").style.display = "none";
        }
      }
    }

    function calculate_highest_valuation(otto, zestimate, realty_mole) {
      const highest = Math.max(
        otto,
        zestimate === undefined || zestimate === null ? 0 : zestimate,
        realty_mole === undefined || realty_mole === null ? 0 : realty_mole,
      );
      return highest;
    }
  }
})();
