import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["bottomSheet"];

  connect() {
    this.element.addEventListener(
      "load-comments",
      this.loadComments.bind(this),
    );
  }

  close() {
    this.bottomSheetTarget.classList.add("hidden");
  }

  closeAllMenus() {
    const allMenus = document.querySelectorAll(
      '[data-floating-options-target="menu"]',
    );
    allMenus.forEach((menu) => { menu.classList.add("hidden"); });
  }

  async loadComments(event) {
    const { boardPropertyId, streetAddress } = event.detail;
    const commentFrameElement =
      this.bottomSheetTarget.querySelector(".comments-frame");
    commentFrameElement.id = `board_property_comments_${boardPropertyId}_mobile`;

    const newCommentForm =
      this.bottomSheetTarget.querySelector("#new_comment_form");
    newCommentForm.action = `/board_properties/${boardPropertyId}/board_property_comments`;

    const boardPropertyIdField = newCommentForm.querySelector(
      "#new_comment_board_property_id",
    );
    boardPropertyIdField.value = boardPropertyId;

    const addressTitleElement = this.bottomSheetTarget.querySelector(
      "#address-title-comments",
    );

    const url = `/board_properties/${boardPropertyId}/board_property_comments`;

    try {
      const response = await fetch(url + "?context=mobile", {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        },
      });

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const turboStreamHtml = await response.text();
      commentFrameElement.innerHTML = turboStreamHtml;
      addressTitleElement.innerHTML = streetAddress;
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  }

  async editComment(event) {
    event.preventDefault();
    this.closeAllMenus();
    const url = event.currentTarget.getAttribute("href");
    const match = url.match(
      /\/board_properties\/(\d+)\/board_property_comments\/(\d+)\/edit/,
    );

    let boardPropertyId, boardPropertyCommentId;

    if (match) {
      boardPropertyId = match[1];
      boardPropertyCommentId = match[2];
    } else {
      console.error("Error: Could not extract IDs from URL");
      return;
    }

    try {
      const response = await fetch(url + "?context=mobile", {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const turboStreamHtml = await response.text();
      const commentDiv = document.getElementById(
        `board_property_comment_${boardPropertyCommentId}`,
      );

      if (commentDiv) {
        commentDiv.innerHTML = turboStreamHtml;
      }
    } catch (error) {
      console.error("Error fetching edit form:", error);
    }
  }

  async createComment(event) {
    event.preventDefault();

    const boardPropertyId = this.bottomSheetTarget.dataset.boardPropertyId;
    console.log("boardPropertyId", boardPropertyId);

    const form = this.bottomSheetTarget.querySelector("#new_comment_form");
    const formData = new FormData(form);

    try {
      const response = await fetch(form.action, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          Accept: "text/vnd.turbo-stream.html",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const turboStreamHtml = await response.text();
      const commentsDiv = document.getElementById(
        `board_property_comments_${boardPropertyId}`,
      );

      commentsDiv.insertAdjacentHTML("beforeend", turboStreamHtml);
      form.reset();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  }

  async cancelEdit(event) {
    event.preventDefault();

    const url = event.currentTarget.getAttribute("href");
    const boardPropertyId = event.currentTarget.dataset.boardPropertyId;
    const boardPropertyCommentId =
      event.currentTarget.dataset.boardPropertyCommentId;

    try {
      const response = await fetch(url + "?context=mobile", {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const turboStreamHtml = await response.text();
      const commentDiv = document.getElementById(
        `board_property_comment_${boardPropertyCommentId}`,
      );

      if (commentDiv) {
        commentDiv.innerHTML = turboStreamHtml;
      }
    } catch (error) {
      console.error("Error fetching edit form:", error);
    }
  }
}
