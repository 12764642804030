(function () {
  if (document.URL.includes("add_property_step5")) {
    const zestimate = document.getElementById("zestimateVal");
    const realty_mole = document.getElementById("realtyMoleVal");
    const selling_price = document.getElementById("step_selling_price");
    const min_price = document.getElementById("step_min_price");
    const mortgage = document.getElementById("step_mortgage");
    const otto_possible_profit = document.getElementById("otto_possible_profit");
    const savings_with_otto = document.getElementById("savings_with_otto");
    let on_load_min_price = min_price.value;
    const USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    if (on_load_min_price !== "") {
      calculate_savings();
    }

    selling_price.addEventListener("keyup", calculate_savings);
    min_price.addEventListener("keyup", calculate_savings);
    mortgage.addEventListener("keyup", calculate_savings);

    function calculate_savings() {
      const selling_price_value = selling_price.value.replace(/[^0-9.-]+/g, "");
      const min_price_value = min_price.value.replace(/[^0-9.-]+/g, "");
      const mortgage_value = mortgage.value.replace(/[^0-9.-]+/g, "");

      const profit_lower = parseInt(min_price_value) - parseInt(mortgage_value);
      const profit_upper =
        parseInt(selling_price_value) - parseInt(mortgage_value);
      otto_possible_profit.innerHTML =
        USDollar.format(profit_lower) + " - " + USDollar.format(profit_upper);

      const savings_lower = parseInt(min_price_value) * 0.05;
      const savings_upper = parseInt(selling_price_value) * 0.06;
      savings_with_otto.innerHTML =
        USDollar.format(savings_lower) + " - " + USDollar.format(savings_upper);

      selling_price.value = USDollar.format(selling_price_value);
      min_price.value = USDollar.format(min_price_value);
      mortgage.value = USDollar.format(mortgage_value);

      if (on_load_min_price !== "" && on_load_min_price !== "$0") {
        on_load_min_price = on_load_min_price.replace(/[^0-9.-]+/g, "");
        const max_target_price = recalculate_max_target_price(on_load_min_price);
        if (min_price_value > (on_load_min_price / 0.85) * 0.95) {
          document.getElementById("min_offer_alert").style.display = "block";
          min_price.value = USDollar.format((on_load_min_price / 0.85) * 0.95);
        } else {
          document.getElementById("min_offer_alert").style.display = "none";
        }
        if (selling_price_value > (max_target_price / 0.85) * 1.2) {
          document.getElementById("selling_price_alert").style.display =
            "block";
        } else {
          document.getElementById("selling_price_alert").style.display = "none";
        }
      }
    }

    function recalculate_max_target_price(on_load_valuation) {
      const zestimate_value = zestimate.innerHTML.replace(/[^0-9.-]+/g, "");
      const realty_mole_value = realty_mole.innerHTML.replace(/[^0-9.-]+/g, "");
      return Math.max(on_load_valuation, zestimate_value, realty_mole_value);
    }
  }
})();
