import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formContainer", "fileInput", "submitButton", "errorMessage", "deleteButton", "loadingIndicator"]

  loadMedia(event) {
    const selectedValue = event.target.value;
    const thumbnailFrame = document.getElementById('thumbnail-frame');

    if (selectedValue === "new_video") {
      this.showForm();
      this.hideDeleteButton()
      thumbnailFrame.src = ''; 
      thumbnailFrame.innerHTML = ''; 
    } else if (selectedValue === "no_video") {
      this.hideForm();
      this.hideDeleteButton()
      thumbnailFrame.src = ''; 
      thumbnailFrame.innerHTML = ''; 
      // Emit custom event indicating no video is selected
      const mediaSelectedEvent = new CustomEvent('media-selected', { detail: { selectedId: '' }, bubbles: true });
      window.dispatchEvent(mediaSelectedEvent);
    } else if (selectedValue) {
      this.hideForm();
      this.showDeleteButton(selectedValue)
      thumbnailFrame.src = `/loan_officer_media_uploads/${selectedValue}/thumbnail`;
      // Emit custom event with selected media ID
      const mediaSelectedEvent = new CustomEvent('media-selected', { detail: { selectedId: selectedValue }, bubbles: true });
      window.dispatchEvent(mediaSelectedEvent);
    } else {
      this.hideForm();
      this.hideDeleteButton()
      thumbnailFrame.src = ''; 
      thumbnailFrame.innerHTML = ''; 
    }
  }

  updateThumbnail(selectedValue) {
    const thumbnailFrame = document.getElementById('thumbnail-frame');
    if (selectedValue === "no_video") {
      thumbnailFrame.src = ''; 
    } else {
      thumbnailFrame.src = `/loan_officer_media_uploads/${selectedValue}/thumbnail`;
    }
  }

  showForm() {
    this.formContainerTarget.classList.remove("hidden")
    this.fileInputTarget.value = ''
    this.errorMessageTarget.textContent = ''
  }

  hideForm() {
    this.formContainerTarget.classList.add("hidden")
  }

  validateFile() {
    console.log('file validation')
    const file = this.fileInputTarget.files[0]
    console.log('file', file)
    if (file && file.type === "video/mp4") {
      console.log('valid file')
      this.setButtonState(true)
      this.errorMessageTarget.textContent = ''
    } else {
      console.log('invalid file')
      this.setButtonState(false)
      this.errorMessageTarget.textContent = "Please select a valid .mp4 video file."
    }
  }

  setButtonState(enabled) {
    const submitButton = this.submitButtonTarget
    console.log('submitButton', submitButton)
    if (submitButton) {
      submitButton.disabled = !enabled;
      if (!enabled) {
        console.log('disabled')
        submitButton.classList.add('opacity-50', 'cursor-not-allowed');
        submitButton.classList.remove('hover:bg-blue-600', 'cursor-pointer');
      } else {
        console.log('enabled')
        submitButton.classList.remove('opacity-50', 'cursor-not-allowed');
        submitButton.classList.add('hover:bg-blue-600', 'cursor-pointer');
        
      }
    }
  }

  submit(event) {
    console.log('submitting')
    if (this.submitButtonTarget.disabled) {
      event.preventDefault()
      this.errorMessageTarget.textContent = "Please select a valid .mp4 video file before uploading."
    } else {
      console.log('show loading')
      this.showLoadingIndicator()
    }
  }

  showLoadingIndicator() {
    this.loadingIndicatorTarget.classList.remove("hidden")
  }

  hideLoadingIndicator() {
    this.loadingIndicatorTarget.classList.add("hidden")
  }

  showDeleteButton(mediaUploadId) {
    this.deleteButtonTarget.href = `/loan_officer_media_uploads/${mediaUploadId}`
    this.deleteButtonTarget.classList.remove("hidden")
  }

  hideDeleteButton() {
    this.deleteButtonTarget.classList.add("hidden")
  }

  async deleteMedia(event) {
    event.preventDefault()
    const url = this.deleteButtonTarget.href

    if (!confirm("Are you sure you want to delete this video?")) {
      return
    }

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      }
    })

    if (response.ok) {
      const result = await response.json()
      alert(result.message)
      this.hideDeleteButton()
      document.getElementById('thumbnail-frame').src = ''
      window.location.href = '/lender-hub/edit_email'
    } else {
      const errorResult = await response.json()
      alert(errorResult.message)
    }
  }
}
