import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shareButton", "reviewListing"]

  connect() {
    if (this.hasReviewListingTarget) {
      const reviewUrl = encodeURIComponent(this.reviewListingTarget.href.replace(/\/edit$/, ''));
      const newHref = this.shareButtonTarget.href + '&url=' + reviewUrl;
      this.shareButtonTarget.href = newHref;
    }
  }
}
