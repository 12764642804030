import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["board"]
    
  connect() {
    console.log('connected')
    this.selectedBoards = [];
  }

  toggleBoard(event) {
    event.preventDefault();
    const boardElement = event.currentTarget;
    const boardId = boardElement.dataset.boardId;
    boardElement.classList.toggle('selected');

    const index = this.selectedBoards.indexOf(boardId);
    if (index >= 0) {
      this.selectedBoards.splice(index, 1);
    } else {
      this.selectedBoards.push(boardId);
    }

    console.log('selectedboards', this.selectedBoards)

    this.updateSelectedCount();
  }

  updateSelectedCount() {
    const countElement = document.getElementById('selected-count');
    const submitButton = document.getElementById('submit-button');

    if (countElement) {
      countElement.textContent = `${this.selectedBoards.length} board(s) selected`;
    }

    if (submitButton) {
      submitButton.disabled = this.selectedBoards.length === 0;
    }
  }
    

  submit(event) {
    event.preventDefault(); 
    const form = document.getElementById('board-property-form');
    const selectedBoardIdsInput = document.getElementById('selected-board-ids');
  
    if (form && selectedBoardIdsInput) {
      selectedBoardIdsInput.value = JSON.stringify(this.selectedBoards);
      form.submit();
    }
  }
}
