import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "continueButton", "form"]

  select(event) {
    this.optionTargets.forEach((element) => {
      element.classList.remove('bg-teal', 'border-blue-700', 'text-white');
    });
    event.currentTarget.classList.add('bg-teal', 'border-blue-700', 'text-white');
    this.continueButtonTarget.value = event.currentTarget.dataset.value;
  }

  submit(event) {
    event.preventDefault();
    const formData = new FormData(this.formTarget);

    fetch(this.formTarget.action, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      credentials: 'same-origin'
    }).then(response => {
    }).catch(error => {
    });
  }
}
