(function () {
  if (document.URL.includes("bids/new")) {
    const offer_price = document.getElementById("offer_price");
    const seller_concession = document.getElementById("seller_concession");
    const calc_offer_price = document.getElementById("calc_offer_price");
    const calc_seller_concession = document.getElementById(
      "calc_seller_concession",
    );
    const calc_commission = document.getElementById("calc_commission");
    // let down_payment = document.getElementById("down_payment");
    // let deposit = document.getElementById("deposit");
    let commission = 0; // checkbox with 2% only if there's a realtor
    const USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    document.addEventListener("DOMContentLoaded", calculate_net_offer);
    offer_price.addEventListener("keyup", calculate_net_offer);
    seller_concession.addEventListener("keyup", calculate_net_offer);
    // down_payment.addEventListener("keyup", calculate_net_offer);
    // deposit.addEventListener("keyup", calculate_net_offer);

    function calculate_net_offer() {
      const offer_price_value = offer_price.value.replace(/[^0-9.-]+/g, "");
      let seller_concession_value = seller_concession.value.replace(
        /[^0-9.-]+/g,
        "",
      );
      // let down_payment_value = down_payment.value.replace(/[^0-9.-]+/g,"");
      // let deposit_value = deposit.value.replace(/[^0-9.-]+/g,"");

      if (has_realtor) {
        commission = offer_price_value * 0.02;
      }

      seller_concession_value =
        seller_concession_value === "" ? 0 : parseInt(seller_concession_value);
      const net_offer =
        parseInt(offer_price_value) - commission - seller_concession_value;
      calc_offer_price.innerHTML = USDollar.format(
        offer_price_value === "" ? 0 : parseInt(offer_price_value),
      );
      calc_seller_concession.innerHTML = USDollar.format(
        seller_concession_value,
      );
      calc_commission.innerHTML = USDollar.format(commission);
      if (net_offer > 0) {
        calc_net_offer.innerHTML = USDollar.format(net_offer);
      }
      offer_price.value = USDollar.format(offer_price_value);
      seller_concession.value = USDollar.format(seller_concession_value);
      // down_payment.value = USDollar.format(down_payment_value);
      // deposit.value = USDollar.format(deposit_value);

      if (min_offer !== "" && min_offer !== "$0" && min_offer !== "0") {
        if (min_offer > offer_price_value) {
          document.getElementById("min_offer_alert").style.display = "block";
        } else {
          document.getElementById("min_offer_alert").style.display = "none";
        }
      }

      if (max_offer !== "" && max_offer !== "$0" && max_offer !== "0") {
        if (max_offer < offer_price_value) {
          document.getElementById("max_offer_alert").style.display = "block";
          document.getElementById("bid_submit").disabled = true;
        } else {
          document.getElementById("max_offer_alert").style.display = "none";
          document.getElementById("bid_submit").disabled = false;
        }
      }
    }

    const closing_date = document.getElementById("closing_date");
    const closing_date_days = document.getElementById("closing_date_days");

    closing_date.addEventListener("change", function () {
      const diff = new Date(closing_date.value).getTime() - new Date().getTime();
      const diff_days = Math.ceil(diff / (1000 * 3600 * 24));
      closing_date_days.value = diff_days;
    });
  }
})();
