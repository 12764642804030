import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["status"];

  connect() {
    console.log(this.element);
    addEventListener("direct-uploads:start", this.startUpload.bind(this));
  }

  startUpload() {
    const el = this.statusTarget;
    el.classList.remove("hidden");
  }
}
