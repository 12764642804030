import { Controller } from "@hotwired/stimulus";
import mapbox from "mapbox-gl";
import debounce from "../custom/debounce";

export default class extends Controller {
  mapboxToken =
    "pk.eyJ1IjoibWNpb2NjYTg5IiwiYSI6ImNsODY5a21sNjB0OXEzbnQ5bGxxNnhnangifQ.vrKWYQ8gm_PKGjaypu6K2A";

  markerLayerId = "propertyMarkerLayer";
  markers = [];

  connect() {
    let coords;
    this.static = !!this.element.dataset.static;
    if (this.static) {
      this.currentPropertyCenter = JSON.parse(
        document.querySelector("meta[name=currentPropertyCoords]").content,
      );
      coords = [
        this.currentPropertyCenter.long,
        this.currentPropertyCenter.lat,
      ];
    }

    mapbox.accessToken = this.mapboxToken;
    this.map = new mapbox.Map({
      container: "map-container", // container ID
      style: "mapbox://styles/mapbox/streets-v11", // style URL
      center: coords || [-73.064034, 41.230698], // starting position [lng, lat]
      zoom: this.static ? 14 : 12, // starting zoom
      projection: "globe", // display the map as a 3D globe
      dragPan: !this.static,
    });

    this.addControls();

    if (!this.element.dataset.static) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises 
      this.map.on("load", this.handleMapMoveEnd.bind(this));
      this.map.on("moveend", debounce(this.handleMapMoveEnd.bind(this), 350));
    } else {
      this.map.scrollZoom.disable();
      this.createStaticMarker();
    }
  }

  createStaticMarker() {
    const metaTag = document.querySelector("meta[name=currentPropertyCoords]");
    
    if (metaTag) {
      const content = metaTag.getAttribute("content");
      
      if (content) {
        const point = JSON.parse(content);
        // Custom bookmark
        new mapbox.Marker({ color: 'var(--primary-color)' })
          .setLngLat([point.long, point.lat])
          .addTo(this.map);
      }
    }
  }
  

  addControls() {
    if (this.static) return;

    this.map.addControl(
      new mapbox.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
    );
  }

  async handleMapMoveEnd() {
    const zoom = this.map.getZoom();
    const { _sw: sw, _ne: ne } = this.map.getBounds();
    const swPoint = `${sw.lng},${sw.lat}`;
    const nePoint = `${ne.lng},${ne.lat}`;

    try {
      const response = await fetch(
        `/map/properties?sw=${swPoint}&ne=${nePoint}&zoom=${zoom}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const json = await response.json();

      this.drawMarkers(json.properties);
    } catch (e) {
      console.log(e);
    }
  }

  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.remove();
    });
  }

  drawMarkers(points) {
    this.clearMarkers();

    if (this.map.zoom > 14) return;

    this.markers = points.map((point) => {
      // Custom bookmarker
      const marker = new mapbox.Marker({ color: 'var(--primary-color)' })
        .setLngLat([point.long, point.lat])
        .addTo(this.map);

      return marker;
    });
  }
}

