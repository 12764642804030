import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputs", "autoComplete", "form"];

  connect() {
    this.inputsTargets.forEach((el) => {
      const index = el.dataset.otpStepIndex;
      this.addChangeListener(el, index);
      this.addPasteListener(el);
    });

    const ac = new AbortController();
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        handleOTP(otp.code);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleOTP(code) {
    this.inputsTargets.forEach((el, index) => {
      el.value = code[index];
    });
    this.inputsTargets[this.inputsTargets.length - 1].focus();
    this.formTarget.submit();
  }

  addChangeListener(el, index) {
    const i = parseInt(index, 10);

    el.addEventListener("keyup", (event) => {
      event.preventDefault();
      const { target } = event;
      const nextEl = this.inputsTargets[i + 1];

      if (event.key === "Backspace") {
        event.target.value = "";
        return this.inputsTargets[i - 1].focus();
      }

      if (target.value.length > 1) {
        target.value = target.value[1];
      }

      const value = parseInt(target.value, 10);
      if (nextEl && value > -1) nextEl.focus();
    });
  }

  addPasteListener(el) {
    el.addEventListener("paste", (event) => {
      event.preventDefault();
      const code = (event.clipboardData || window.clipboardData)
        .getData("text")
        .trim();
      this.inputsTargets.forEach((el, index) => {
        el.value = code[index];
      });

      this.inputsTargets[this.inputsTargets.length - 1].focus();
    });
  }
}
