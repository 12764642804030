import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "totalPrice", "warningMessage", "checkoutButton"]
  static values = { numberOfContacts: Number }

  connect() {
    this.fetchTiers();
  }

  async fetchTiers() {
    const response = await fetch(`/lender-hub/tiers`);
    const tiers = await response.json();
    this.tiers = tiers.map(tier => ({ ...tier, flat_amount: tier.flat_amount / 100 })); 
    this.setCurrentTierIndexForContacts(this.numberOfContactsValue);
    this.updateUI();
  }

  setCurrentTierIndexForContacts(numberOfContacts) {
    const tierIndex = this.tiers.findIndex(tier => numberOfContacts <= tier.up_to);
    this.currentTierIndex = tierIndex >= 0 ? tierIndex : this.tiers.length - 1;
  }

  increment() {
    if (this.currentTierIndex < this.tiers.length - 1) {
      this.currentTierIndex++;
      this.updateUI();
    }
  }

  decrement() {
    if (this.currentTierIndex > 0) {
      this.currentTierIndex--;
      this.updateUI();
    }
  }

  updateUI() {
    const currentTier = this.tiers[this.currentTierIndex];
    this.inputTarget.value = currentTier.up_to;
    this.totalPriceTarget.innerText = `$${currentTier.flat_amount}`;
  
    const canProceed = this.numberOfContactsValue <= currentTier.up_to;
    this.checkoutButtonTarget.disabled = !canProceed;
    
    if (canProceed) {
      this.checkoutButtonTarget.classList.remove('disabled:opacity-25');
      this.checkoutButtonTarget.classList.add('hover:bg-teal');
    } else {
      this.checkoutButtonTarget.classList.add('disabled:opacity-25');
      this.checkoutButtonTarget.classList.remove('hover:bg-teal');
    }
    
    this.warningMessageTarget.style.display = canProceed ? 'none' : 'block';
  }  
}
