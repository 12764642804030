import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    // you may need specified width for the parent container for all this to work
  static targets = ["text"]

  connect() {
    this.adjustTextSize();
    window.addEventListener('resize', () => { this.adjustTextSize(); });
  }

  adjustTextSize() {
    this.textTargets.forEach((textContainer) => {
      const textElement = textContainer.querySelector('p');
      this.resizeToFit(textElement);
    });
  }

  resizeToFit(element) {
    const computedStyle = window.getComputedStyle(element);
    const minFontSize = 12;
    let fontSize = parseFloat(computedStyle.fontSize);
    const maxFontSize = 120;
    const step = 0.5;


    while (this.hasOverflow(element) && fontSize > minFontSize) {
        // shrink until overflow disappears or you hit min
      fontSize -= step;
      element.style.fontSize = `${fontSize}px`;
    }

    while (!this.hasOverflow(element) && fontSize < maxFontSize) {
        // grow until you hit max or overflow appears
      fontSize += step;
      element.style.fontSize = `${fontSize}px`;
    }
  
    if (this.hasOverflow(element) && fontSize > minFontSize) {
        // shrink back down one step
      fontSize -= step;
      element.style.fontSize = `${fontSize}px`;
    }
    
  }

  hasOverflow(element) {
    return element.scrollWidth > element.clientWidth;
  }

  disconnect() {
    window.removeEventListener('resize', () => { this.adjustTextSize(); });
  }
}
