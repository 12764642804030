import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        apiKey: String,
        productId: Number,
        pricingPlanId: Number,
        tierId: Number,
        numberOfContacts: Number,
    }

  connect() {
    this.initializeStripe();
  }

  async initializeStripe() {
    if (!window.Stripe) {
      console.error("Stripe.js hasn't loaded yet.");
      return;
    }

    const stripe = Stripe(this.apiKeyValue);

    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    const response = await fetch("/create-checkout-session", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        body: JSON.stringify({
            product_id: this.productIdValue,
            pricing_plan_id: this.pricingPlanIdValue,
            tier_id: this.tierIdValue,
            number_of_contacts: this.numberOfContactsValue
        })
    });

    if (!response.ok) {
        console.error("Failed to create checkout session.");
        // Optionally, you could alert the user before redirecting
        alert("Checkout failed. Redirecting to Lender Hub...");
        window.location.href = "/lender-hub";
        return;
    }
    
    const { clientSecret, error } = await response.json();
    
    if (error) {
        console.error(error);
        alert(error); // Inform the user of the error
        window.location.href = "/lender-hub"; // Redirect to /lender-hub
        return;
    }

    const checkout = await stripe.initEmbeddedCheckout({
      clientSecret,
    });

    checkout.mount('#checkout');
  }
}
