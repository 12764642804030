import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "overlay"];

  connect() {
    this.overlayTarget.style.display = "none";
    this.overlayTarget.style.opacity = "0";
    this.overlayTarget.style.zIndex = "1";
    this.menuTarget.style.display = "none";
    this.menuTarget.style.right = "-100%";
    this.menuTarget.style.zIndex = "2";
  }

  show() {
    this.overlayTarget.style.display = "flex";
    this.overlayTarget.style.opacity = "0.7";
    this.menuTarget.style.display = "block";
    this.menuTarget.style.right = "0";
  }

  hide() {
    this.overlayTarget.style.display = "none";
    this.overlayTarget.style.opacity = "0";
    this.menuTarget.style.display = "none";
    this.menuTarget.style.right = "-100%";
  }
}
