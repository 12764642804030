import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  stepup(event) {
    event.preventDefault();
    const input = this.inputTarget;
    input.value = parseInt(input.value, 10) + 1;
  }

  stepdown(event) {
    event.preventDefault();
    const input = this.inputTarget;
    const value = parseInt(input.value, 10);
    if (value > 0) {
      input.value = value - 1;
    }
  }
}
