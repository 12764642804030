import "fslightbox";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open = false;

  connect() {
    this.photoUrls = JSON.parse(
      document.querySelector("meta[name=propertyPhotos]").content,
    );
    const coverPhotoEl = document.querySelector("meta[name=coverPhoto]");
    if (coverPhotoEl) {
      this.photoUrls.unshift(coverPhotoEl.content);
    }
    this.lightbox = new FsLightbox();
    this.lightbox.props.type = "image";
    this.lightbox.props.sources = this.photoUrls;
  }

  toggle() {
    if (this.open) {
      this.lightbox.close();
    } else {
      this.lightbox.open();
    }
  }
}
