(function () {
  if (document.URL.includes("payment_sources/new")) {
    const purchase_price = document.getElementById(
      "payment_source_purchase_price",
    );
    const down_payment = document.getElementById("payment_source_down_payment");
    const yes_approved_amount = document.getElementById(
      "yes_pre_approval_approved_amount",
    );
    const total_liquid_assets = document.getElementById("total_liquid_assets");
    const USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    const elements = [
      purchase_price,
      down_payment,
      yes_approved_amount,
      total_liquid_assets,
    ];

    elements.forEach(function (element) {
      element.addEventListener("keyup", format_currency);
    });

    function format_currency() {
      elements.forEach(function (element) {
        element.value = USDollar.format(
          element.value.replace(/[^0-9.-]+/g, ""),
        );
      });
    }
  }

  // Liquid assets new payment source
  // <script>
  //     let assetsProof = document.getElementById("assetsProof");
  //     let assetsProofLabel = document.getElementById("assetsProofLabel");
  //     let assetsProofButton = document.getElementById("assetsProofButton");
  //
  //     assetsProof.addEventListener("change", function() {
  //     showAttachedFile(assetsProof, assetsProofLabel, assetsProofButton);
  // });
  // </script>
})();
