import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "tooltip"];

  show(event) {
    const button = event.currentTarget;
    const tooltipId = button.dataset.buttonTooltipId;
    const tooltip = this.getTooltipById(tooltipId);
    const container = button.closest("[data-tooltip-container]");

    let top = button.offsetTop;
    let left = button.offsetLeft;

    top += button.offsetHeight;

    top += container.scrollTop;
    left += container.scrollLeft;

    top += 180;
    // I don't know why, but I need a manual adjustment like this for now... for floating options container, we don't need this

    tooltip.style.position = "absolute";
    tooltip.style.top = `${top}px`;
    tooltip.style.left = `${left}px`;

    tooltip.classList.remove("hidden");
    tooltip.style.visibility = "visible";
  }

  hide(event) {
    const button = event.currentTarget;
    const tooltipId = button.dataset.buttonTooltipId;
    const tooltip = this.getTooltipById(tooltipId);

    if (tooltip) {
      tooltip.classList.add("hidden");
    }
  }

  getTooltipById(tooltipId) {
    return this.element.querySelector(`[data-tooltip-id="${tooltipId}"]`);
  }
}
