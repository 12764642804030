import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["popup", "commentsLink", "address"];

  toggleCommentsPopup(event) {
    event.preventDefault();
    if (window.innerWidth >= 768) {
      this.loadComments();

      const commentPopup = this.popupTarget;
      commentPopup.classList.toggle("hidden");

      const button = event.currentTarget;
      const container = button.closest("[data-board-property-container]");

      let top = button.offsetTop;
      let left = button.offsetLeft;

      top += button.offsetHeight;
      left += button.offsetWidth;

      left += 10;
      top -= 40;

      top += container.scrollTop;
      left += container.scrollLeft;

      commentPopup.style.position = "absolute";
      commentPopup.style.top = `${top}px`;
      commentPopup.style.left = `${left}px`;
    } else {
      const boardPropertyId = this.element.dataset.boardPropertyId;
      const fullAddress = this.addressTarget.textContent.trim();
      const streetAddress = fullAddress.split(" - ")[0];

      const bottomSheetElement = document.getElementById(
        "bottom-sheet-comments",
      );
      bottomSheetElement.dataset.boardPropertyId = boardPropertyId;

      bottomSheetElement.classList.remove("hidden");

      const customEvent = new CustomEvent("load-comments", {
        detail: { boardPropertyId, streetAddress },
      });
      bottomSheetElement.dispatchEvent(customEvent);
    }
  }

  closePopup(event) {
    event.preventDefault();
    this.popupTarget.classList.add("hidden");
  }

  async loadComments() {
    const boardPropertyId = this.element.dataset.boardPropertyId;
    const url = this.commentsLinkTarget.dataset.boardPropertyUrl;

    try {
      const response = await fetch(url + "?context=desktop", {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const turboStreamHtml = await response.text();
      const turboFrame = document.getElementById(
        `board_property_comments_${boardPropertyId}_desktop`,
      );
      turboFrame.innerHTML = turboStreamHtml;
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  }

  async createComment(event) {
    event.preventDefault();

    const boardPropertyId = this.element.dataset.boardPropertyId;
    const form = this.element.querySelector("#new_board_property_comment");
    const formData = new FormData(form);

    try {
      const response = await fetch(form.action, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          Accept: "text/vnd.turbo-stream.html",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const turboStreamHtml = await response.text();
      const commentsDiv = document.getElementById(
        `board_property_comments_${boardPropertyId}`,
      );

      commentsDiv.insertAdjacentHTML("beforeend", turboStreamHtml);
      form.reset();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  }

  async editComment(event) {
    event.preventDefault();
    this.closeAllMenus();
    const url = event.currentTarget.getAttribute("href");
    const match = url.match(
      /\/board_properties\/(\d+)\/board_property_comments\/(\d+)\/edit/,
    );

    let boardPropertyId, boardPropertyCommentId;

    if (match) {
      boardPropertyId = match[1];
      boardPropertyCommentId = match[2];
    } else {
      console.error("Error: Could not extract IDs from URL");
      return;
    }

    try {
      const response = await fetch(url, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const turboStreamHtml = await response.text();
      const commentDiv = document.getElementById(
        `board_property_comment_${boardPropertyCommentId}`,
      );

      if (commentDiv) {
        commentDiv.innerHTML = turboStreamHtml;
      }
    } catch (error) {
      console.error("Error fetching edit form:", error);
    }
  }

  async cancelEdit(event) {
    event.preventDefault();
    console.log('cancel clicked')

    const url = event.currentTarget.getAttribute("href");
    console.log('url', url)
    const boardPropertyId = event.currentTarget.dataset.boardPropertyId;
    const boardPropertyCommentId =
      event.currentTarget.dataset.boardPropertyCommentId;

    try {
      const response = await fetch(url + "?context=desktop", {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        },

      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const turboStreamHtml = await response.text();
      console.log('turboStreamHtml', turboStreamHtml)
      const commentDiv = document.getElementById(
        `board_property_comment_${boardPropertyCommentId}`,
      );

      if (commentDiv) {
        commentDiv.innerHTML = turboStreamHtml;
      }
    } catch (error) {
      console.error("Error fetching edit form:", error);
    }
  }

  async updateComment(event) {
    event.preventDefault();
    const button = event.target;
    const form = button.closest("form");
    const formData = new FormData(form);
    const url = form.action;

    const match = url.match(
      /\/board_properties\/(\d+)\/board_property_comments\/(\d+)/,
    );

    let boardPropertyId, boardPropertyCommentId;

    if (match) {
      boardPropertyId = match[1];
      boardPropertyCommentId = match[2];
    } else {
      console.error("Error: Could not extract IDs from URL");
      return;
    }

    try {
      const response = await fetch(url + "?context=desktop", {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          Accept: "text/vnd.turbo-stream.html",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const turboStreamHtml = await response.text();
      const commentDiv = document.getElementById(
        `board_property_comment_${boardPropertyCommentId}`,
      );

      if (commentDiv) {
        commentDiv.innerHTML = turboStreamHtml;
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  }

  closeAllMenus() {
    const allMenus = document.querySelectorAll(
      '[data-floating-options-target="menu"]',
    );
    allMenus.forEach((menu) => { menu.classList.add("hidden"); });
  }
}
